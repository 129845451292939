<template>
  <main id="content" class="main-content page-correction pt-0" role="main" v-if="prova">
    <div class="page-correction__header mb-4">
      <div class="page-correction__header--topbar">
        <div class="container-fluid">
          <div class="row align-items-md-center">
            <div class="col-md-4">
              <ul class="list-inline">
                <li class="list-inline-item mr-3">
                  <a href="#" class="link link-sec" @click.prevent="voltar()">
                    <i class="fas fa-chevron-left mr-3"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-4 text-center">
              <h1 class="fs-14 color-oct" v-if="prova.titulo != null ">{{ prova.titulo }}</h1>
            </div>

            <div class="col-md-4 text-right">
              <a href="#" class="link link-sec">Imprimir</a>
            </div>
          </div>
        </div>
      </div>

      <div class="page-correction__header--details">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              <h1 class="fs-24 color-oct m-0">
                <!-- {{ prova.disciplina.text }} - {{ prova.turma.anoEnsino.titulo }} -->
              </h1>
            </div>
            <div class="col-md-6 text-right">
              <ul class="list-inline align-items-center m-0">
                <li class="list-inline-item">
                  <div class="badge badge-note" style="color: black">
                    Média da Turma: <span style="color: black">0,0</span>
                  </div>
                </li>
                <li class="list-inline-item color-oct">
                  {{ prova.dataHoraInicio | formatDate }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-correction__content px-5">
      <h3 class="fs-24 mb-4">Prova</h3>

      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="media media-correction ml-5">
              <div class="media-body">
                <div class="card">
                  <div class="page-content__charts block__classic p-4 mb-4">
                    <div class="row justify-content-center">
                      <div class="col-md-4 col-xl-3">
                        <div class="block__pie">
                          <p class="title">
                            Média da turma
                          </p>
                          <div class="app-chart">
                            <pie-chart
                              :data="chartDataDesempenho"
                              :options="chartOptions"
                              ref="chartDesempenho"
                            ></pie-chart>
                          </div>

                          <ul class="list-unstyled text-center">
                            <li>
                              <i class="success"></i> Acima da média:
                              <b>{{ percentageCorrect }}%</b>
                            </li>
                            <li>
                              <i class="error"></i> Abaixo da média:
                              <b>{{ wrongPercentage }}%</b>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="col-md-4 col-xl-3"></div>
                      <div class="col-md-4 col-xl-3">
                        <div class="block__pie">
                          <p class="title">
                            Questões
                            <span
                              class="icon-tooltip"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                              ><i class="help"></i
                            ></span>
                          </p>

                          <div class="block__pie--canvas">
                            <div class="app-chart">
                              <pie-chart
                                :data="chartDataFinalizados"
                                :options="chartOptions"
                                ref="chartFinalizacao"
                              ></pie-chart>
                            </div>
                          </div>

                          <ul class="list-unstyled text-center">
                            <li>
                              <i class="success"></i>Corretas:
                              <strong>{{ percentageDid }}%</strong>
                            </li>
                            <li>
                              <i class="error"></i>Erradas:
                              <strong>{{ percentageDidNot }}%</strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="media media-correction"
              v-for="(item, index) in this.prova.provaQuestoes"
              :key="item.id"
            >
              <span class="counter mr-1">{{ index + 1 }}</span>

              <div class="media-body">
                <div class="card">
                  <div class="card-header">
                    <strong>Valor da Questão: {{ item.peso }} ponto </strong>
                  </div>
                  <div class="card-body">
                    <h5>
                      Enunciado
                      <span
                        class="icon-tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                        ><i class="help"></i
                      ></span>
                    </h5>

                    <p v-html="item.questao.enunciado"></p>
                  </div>

                  <div class="card-footer">
                    <h5 class="mb-3">
                      Respostas
                      <span
                        class="icon-tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                        ><i class="help"></i
                      ></span>
                    </h5>

                    <ul
                      class="list-answers list-unstyled"
                      v-if="item.questao.alternativas.length > 0"
                    >
                      <div
                        v-for="(item, index) in item.questao.alternativas"
                        :key="item.id"
                      >
                        <li v-if="item.isCorreta" class="media success">
                          <span class="icon">
                            <i class="fas fa-check"></i>
                          </span>

                          <span class="media-body">
                            <span class="alert alert-success" role="alert">
                              <strong
                                >{{ letras[index] }}) {{ item.texto }}</strong
                              >
                            </span>
                          </span>
                        </li>
                        <li v-if="!item.isCorreta" class="media error">
                          <span class="icon">
                            <i class="fas fa-check"></i>
                          </span>

                          <span class="media-body">
                            <span class="alert alert-danger" role="alert">
                              <strong
                                >{{ letras[index] }}) {{ item.texto }}</strong
                              >
                            </span>
                          </span>
                        </li>
                        <br />
                      </div>
                      <!--<li class="media success">
                        <span class="icon">
                          <i class="fas fa-times"></i>
                        </span>

                        <span class="media-body">
                          <span class="alert alert-success" role="alert">
                            <strong>b)</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                          </span>
                        </span>
                      </li>-->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import PieChart from "./PieChart.js";

export default {
  components: {
    PieChart,
  },
  data() {
    return {
      chartOptions: {
        hoverBorderWidth: 20,
        renderOnMount: false,
      },
      showLeftMenu: true,
      prova: null,
      provaResult: null,
      aluno: null,
      letras: ["a", "b", "c", "d", "e", "f", "g", "h", "i"],
      chartDataDesempenho: null,
      chartDataFinalizados: null,
      percentageDid: 0,
      percentageDidNot: 0,
      percentageCorrect: 0,
      wrongPercentage: 0,
    };
  },
  created() {
    this.loadProva();
    this.loadProvaResult()
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY - hh:mm");
      } else {
        return "-";
      }
    },
  },
  methods: {
    voltar() {
      this.$router.go(-1);
    },
    loadProva() {
      this.$api
        .get("provas/" + this.$route.params.idprova)
        .then((response) => {
          debugger;
          this.prova = response.data;
          console.log(this.prova);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    loadProvaResult() {
      this.$api
        .get("estatistica-prova/" + this.$route.params.idprova)
        .then((response) => {
          this.provaResult = response.data;
          let c = this.provaResult.qtdQuestoesCorretas / this.provaResult.qtdQuestoes;
          let e = this.provaResult.qtdQuestoesErradas / this.provaResult.qtdQuestoes;
          let cFinal = c * 100;

          this.percentageCorrect = parseFloat(cFinal).toFixed(2);

          let eFinal = e * 100;
          this.wrongPercentage = parseFloat(eFinal).toFixed(2);
          this.chartDataDesempenho = {
            hoverBackgroundColor: "red",
            hoverBorderWidth: 10,
            labels: ["Corretas", "Erradas"],
            datasets: [
              {
                backgroundColor: ["#41B883", "#E46651"],
                data: [
                  this.provaResult.qtdQuestoesCorretas,
                  this.provaResult.qtdQuestoesErradas,
                ],
              },
            ],
          };
          let naoFinalizaram = this.provaResult.qtdAlunoProva - this.provaResult.qtdFinalizacao;
          let fez = this.provaResult.qtdFinalizacao / this.provaResult.qtdAlunoProva;
          let naoFez = naoFinalizaram / this.provaResult.qtdAlunoProva;
          this.percentageDid = fez * 100;
          this.percentageDidNot = naoFez * 100;

          this.chartDataFinalizados = {
            hoverBackgroundColor: "red",
            hoverBorderWidth: 10,
            labels: ["Finalizaram a prova", "Não finalizaram a prova"],
            datasets: [
              {
                backgroundColor: ["#41B883", "#E46651"],
                data: [this.provaResult.qtdFinalizacao, naoFinalizaram],
              },
            ],
          };
          this.$refs.chartDesempenho.renderCustom(this.chartDataDesempenho);
          this.$refs.chartFinalizacao.renderCustom(this.chartDataFinalizados);
          // this.loadAlunos();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.app-chart {
  width: 275px;
  height: 275px;
}
</style>
